<template>
  <div
    :class="[$style.root, widthClassString, viewOptionClassString]"
    :data-view-option="block?.view_option"
  >
    <CoreTextComponent
      :string="string"
      :class="$style.text"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const string = computed(() => i18nGetLocalizedString(props?.block, 'i18n_richtext_en'))

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})

const viewOptionClassString = computed(() => {
  switch (i18nGetLocalizedData(props?.block)?.view_option)
  {
    case 'large':
      return 'font-size-medium'
    case 'small':
    case 'indented':
    case 'note':
      return 'font-size-small'
    default:
      return 'font-size-default'
  }
})
</script>

<style module lang="scss">
.root {
}

.root[data-view-option="indented"] .text {
  width: 75%;
  margin-left: auto;
  color: var(--color--gray--mid);
  @media (max-width: $breakpoint-s) {
    width: 100%;
  }
}

.root[data-view-option="note"] {
  padding: var(--padding--button);
  background-color: var(--color--gray--light);

  border-radius: var(--border-radius--default);
}
</style>
