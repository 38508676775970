<template>
  <div :class="[$style.root, widthClassString]">
    <I18nLinkWithFallback
      :name="linkObject?.routeReference?.name"
      :localizedData="linkObject"
      :class="$style.link"
    >
      <div :class="$style.label">
        {{ $t('shared.readMore') }}
      </div>
      <div :class="$style.title">
        <span>{{ title || $t('shared.more') }}</span> &rarr;
      </div>
    </I18nLinkWithFallback>
  </div>
</template>

<script setup>
const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const linkObject = computed(() => navigationLinkItem(props?.block))

const title = computed(() => i18nGetLocalizedTitle(linkObject?.value))

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})
</script>

<style module>
.root {
  display: flex;
  justify-content: flex-end;
}

.link {
  composes: reset-link from global;

  display: block;
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--card));
  background-color: var(--color--gray--light);
  padding: var(--padding--button);

  min-width: 24ch;
  max-width: 100%;

  transition: background-color var(--transition--default), color var(--transition--default);
}

.link:hover {
  background-color: var(--color--blue);
  color: var(--color--white);
}

.label {
  composes: font-size-x-small from global;
  text-transform: uppercase;
  color: var(--color--gray--dark);

  transition: color var(--transition--default);
}

.link:hover .label {
  color: var(--color--blue--light);
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--unit--spacer);
  flex-wrap: wrap;
}
</style>
