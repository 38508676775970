<template>
  <AccordionRoot
    :class="$style.root"
    type="single"
    :collapsible="true"
    @update:modelValue="updateModelValue"
  >
    <CoreBlocksFaqsFaqItemComponent
      v-for="item in faq?.attributes?.items"
      :key="item?.id"
      :item="item"
      :value="item?.id"
      :active="activeId === item?.id"
    />
  </AccordionRoot>
</template>

<script setup>
const props = defineProps({
  faq: Object
})

const activeId = ref(null)

const updateModelValue = (value) => {
  activeId.value = value
}
</script>

<style module>
.root {
  margin: 0 calc(var(--unit--spacer) * -1);
}
</style>
