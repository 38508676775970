<template>
  <div
    :class="[$style.root, widthClassString]"
    :id="titleToAnhor"
    data-heading-anchor
  >
    {{ title }}
  </div>
</template>

<script setup>
const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})

const title = computed(() => i18nGetLocalizedString(props?.block))

const titleToAnhor = computed(() => stringToAnchor(title.value))
</script>

<style module>
.root {
  composes: font-size-medium from global;
  color: var(--color--blue);
  padding-top: var(--unit--vertical);
  margin-top: calc(var(--unit--vertical) * -1);
}
</style>
