<template>
  <div
    :class="$style.root"
    :data-multiple="images?.length > 1 ? '' : null"
    :data-within-modal="withinModal ? '' : null"
  >
    <div
      v-if="viewOption === 'grid' && randomImages?.length"
      :class="$style.grid"
    >
      <CoreBlocksImagesImageComponent
        v-for="image in randomImages"
        :key="image?.id"
        :image="image"
        :class="$style.image"
      />
    </div>

    <BaseCarouselComponent
      ref="sliderElement"
      :class="$style.carousel"
      type="fade"
      v-else-if="images?.length > 1"
    >
      <BaseCarouselSlideComponent
        v-for="image in images"
        :key="image?.id"
      >
        <CoreImageComponent
          :image="image?.attributes"
        />
      </BaseCarouselSlideComponent>

      <template #bottom="{ slideCount, slideIndex, goToSlide }">
        <ClientOnly>
          <BaseCarouselGoComponent
            :go-to-slide="goToSlide"
            index="<"
            :class="$style.carouselButton"
            :disabled="slideIndex === 1 ? '' : null"
            dir="prev"
          >
            <SvgoArrow :class="$style.svg" />
          </BaseCarouselGoComponent>
          <BaseCarouselGoComponent
            :go-to-slide="goToSlide"
            index=">"
            :class="$style.carouselButton"
            :disabled="slideIndex === slideCount ? '' : null"
            dir="next"
          >
            <SvgoArrow :class="$style.svg" />
          </BaseCarouselGoComponent>
        </ClientOnly>
      </template>
    </BaseCarouselComponent>

    <CoreBlocksImagesImageComponent
      v-else
      :image="images[0]"
      :class="$style.singleImage"
    />
  </div>
</template>

<script setup>
import { sampleSize } from 'lodash-es'

const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const images = computed(() => {
  return i18nGetLocalizedData(props?.block)?.images?.data
})

const randomImages = computed(() => {
  return images.value?.slice(0, 3)
})

const viewOption = computed(() => {
  return i18nGetLocalizedData(props?.block)?.view_option
})
</script>

<style module lang="scss">
.root {
  --carousel--aspect-ratio: 1.8;
}

.root:not([data-within-modal]) {
  padding: 0 var(--unit--horizontal);
}

.root[data-multiple] .carousel [data-orientation="landscape"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, var(--carousel--aspect-ratio));
  --base--image--object-fit: cover;
}

.root[data-multiple] .carousel [data-orientation="square"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, var(--carousel--aspect-ratio));
  --base--image--object-fit: contain;
}

.root .carousel [data-orientation="portrait"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, var(--carousel--aspect-ratio));
  --base--image--object-fit: contain;
}

.carousel {
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--default));
  overflow: hidden;
  background-color: var(--color--gray--light);

  aspect-ratio: var(--carousel--aspect-ratio);

  max-width: var(--container--large);
  margin: auto;
}

.carouselButton {
  composes: reset-button button-round from global;

  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  margin: 0 var(--unit--horizontal);

  transition: opacity var(--transition--default);
}

.carouselButton[disabled] {
  opacity: 0;
}

.carouselButton[dir="prev"] {
  left: 0;
}

.carouselButton[dir="next"] {
  right: 0;
}

.carouselButton[dir="next"] svg {
  transform: rotate(180deg);
}

.grid {
  max-width: var(--container--large);
  margin: auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--unit--horizontal);
  grid-row-gap: calc(var(--unit--vertical) * 3);
  align-items: start;

  @media (max-width: $breakpoint-l) {
    grid-template-columns: repeat(2, 1fr);

    .image {
      &:nth-child(3n) {
        display: none;
      }
    }
  }

  @media (max-width: $breakpoint-s) {
    grid-template-columns: repeat(1, 1fr);

    .image {
      &:nth-child(3n) {
        display: block;
      }
    }
  }
}

.grid

.singleImage {
  max-width: var(--container--large);
  margin: auto;
}
</style>
