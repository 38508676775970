<template>
  <section
    v-if="render"
    :class="$style.root"
  >
    <template
      v-for="block in blocks"
      :key="block?.id"
    >
      <CoreBlocksDownloadsComponent
        v-if="block?.__component === 'blocks.downloads'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksTextComponent
        v-if="block?.__component === 'blocks.text'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksImagesComponent
        v-if="block?.__component === 'blocks.images'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksHeadingComponent
        v-if="block?.__component === 'blocks.heading'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksLinkComponent
        v-if="block?.__component === 'blocks.link'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksFaqsComponent
        v-if="block?.__component === 'blocks.faqs'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksVideoComponent
        v-if="block?.__component === 'blocks.video'"
        :block="block"
        :withinModal="withinModal"
      />
      <CoreBlocksMuxVideoComponent
        v-if="block?.__component === 'blocks.mux-video'"
        :block="block"
      />
      <CoreBlocksExternalLinkComponent
        v-if="block?.__component === 'blocks.external-link'"
        :block="block"
      />
      <CoreBlocksCodeComponent
        v-if="block?.__component === 'blocks.code'"
        :block="block"
      />
    </template>
  </section>
</template>

<script setup>
const props = defineProps({
  blocks: {
    type: [Object, Array]
  },
  withinModal: {
    type: Boolean,
    default: false
  }
})

const blocks = computed(() => {
  return props?.blocks?.filter(i => {
    const localizedData = i18nGetLocalizedData(i)
    if (i?.__component === 'blocks.text') {
      return localizedData?.current_locale || localizedData?.i18n_richtext_en
    } else if (i?.__component === 'blocks.heading') {
      return localizedData?.current_locale || localizedData?.i18n_string_en
    } else if (i?.__component === 'blocks.images') {
      return localizedData?.images?.data?.length > 0
    } else if (i?.__component === 'blocks.link') {
      return navigationLinkItem(i)?.routeReference?.name
    } else if (i?.__component === 'blocks.faqs') {
      return localizedData?.faq_entries?.data?.length > 0
    } else if (i?.__component === 'blocks.video') {
      return localizedData?.video?.url
    } else if (i?.__component === 'blocks.downloads') {
      return localizedData?.downloads?.length > 0 && localizedData?.descriptions
    } else if (i?.__component === 'blocks.mux-video') {
      return localizedData?.mux_asset?.data?.id !== null
    } else if (i?.__component === 'blocks.external-link') {
      return localizedData?.i18n_string_en && localizedData?.i18n_url_en
    } else if (i?.__component === 'blocks.code') {
      return localizedData?.i18n_code_en
    } else {
      return false
    }
  })
})

const render = computed(() => {
  return blocks?.value?.length > 0
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit--vertical) * 2);
}
</style>
