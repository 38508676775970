<template>
  <div
    :class="[$style.root, widthClassString]"
    :data-view-option="block?.view_option"
  >
    <div v-html="string" />
  </div>
</template>

<script setup>
const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const string = computed(() => i18nGetLocalizedString(props?.block, 'i18n_code_en'))

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})
</script>

<style module lang="scss">
.root {
}

.root[data-view-option="indented"] .text {
  width: 75%;
  margin-left: auto;
  color: var(--color--gray--mid);
  @media (max-width: $breakpoint-s) {
    width: 100%;
  }
}

.root[data-view-option="note"] {
  padding: var(--padding--button);
  background-color: var(--color--gray--light);

  border-radius: var(--border-radius--default);
}
</style>
