<template>
<div
  v-if="provider && providerUid"
  :class="widthClassString"
>
  <Player controls>
    <Vimeo
      v-if="provider === 'vimeo'"
      :videoId="providerUid"
    />
    <Youtube
      v-else-if="provider === 'youtube'"
      :videoId="providerUid"
    />
  </Player>
</div>
</template>

<script lang="ts" setup>
import '@vime/core/themes/default.css'
import { Player, Vimeo, Youtube } from '@vime/vue-next';

const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const provider = computed(() => {
  return props?.block?.video?.provider
})

const providerUid = computed(() => {
  return props?.block?.video?.providerUid
})

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})
</script>
