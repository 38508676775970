<template>
  <div
    :class="[$style.root, widthClassString]"
    v-if="title && url"
  >
    <a
      :class="$style.link"
      :href="url"
      target="_blank"
    >
      <div :class="$style.title">
        {{ title }}
      </div>
    </a>
  </div>
</template>

<script setup>
const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const title = computed(() => {
  const object = i18nGetLocalizedData({ ...props?.block })
  return i18nGetLocalizedString(object)
})

const url = computed(() => {
  const object = i18nGetLocalizedData({ i18n_url_en: props?.block?.i18n_url_en, i18n_url_ca: props?.block?.i18n_url_ca, i18n_url_es: props?.block?.i18n_url_es })
  return i18nGetLocalizedString(object, 'i18n_url_en')
})

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})
</script>

<style module>
.root {
  display: flex;
}

.link {
  composes: reset-link from global;

  display: block;
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--card));
  background-color: var(--color--gray--light);
  padding: var(--padding--button);

  max-width: 100%;

  transition: background-color var(--transition--default), color var(--transition--default);
}

.link:hover {
  background-color: var(--color--blue);
  color: var(--color--white);
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--unit--spacer);
  flex-wrap: wrap;
}
</style>
