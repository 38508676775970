<template>
  <div
    :class="$style.root"
    v-if="video"
  >
    <div :class="$style.content">
      <CoreMuxVideoComponent :video="video" />

      <div
        :class="$style.caption"
        v-if="caption"
      >
        {{ caption }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps({
  block: Object
})

const video = computed(() => {
  return props?.block?.mux_asset?.data
})


const caption = computed(() => {
  const caption = {
    i18n_caption_en: video?.value?.attributes?.i18n_caption_en,
    i18n_caption_es: video?.value?.attributes?.i18n_caption_es,
    i18n_caption_ca: video?.value?.attributes?.i18n_caption_ca,
  }
  return i18nGetLocalizedString(i18nGetLocalizedData(caption), 'i18n_caption_en')
})

</script>

<style module>
.root {
  max-width: var(--container--large);

  margin-left: auto;
  margin-right: auto;
  padding-left: var(--unit--horizontal);
  padding-right: var(--unit--horizontal);
  width: 100%;

  display: flex;
  justify-content: center;
}

.content {
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--default));
  background-color: var(--color--gray--light);

  position: relative;
  width: 100%;
}

.caption {
  composes: font-size-x-small from global;

  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;

  padding: var(--padding--button);

  color: var(--color--white);
  background: linear-gradient(transparent, rgba(0,0,0,.25));
}
</style>
