<template>
  <AccordionItem :class="$style.root">
    <div ref="root">
    <AccordionHeader :class="$style.header">
      <AccordionTrigger :class="$style.trigger">
        {{ label }}
        <div :class="$style.buttonContainer">
          <div :class="$style.button" data-outline>
            <SvgoPlus :class="$style.plus" />
            <SvgoMinus :class="$style.minus" />
          </div>
        </div>
      </AccordionTrigger>
    </AccordionHeader>
    <AccordionContent :class="$style.content">
      <CoreTextComponent
        v-if="description"
        :string="description"
        :class="$style.text"
      />
    </AccordionContent>
    </div>
  </AccordionItem>
</template>

<script setup>
const root = ref(null)
const props = defineProps({
  item: Object,
  active: {
    type: Boolean,
    default: false
  }
})

const isActive = computed(() => props?.active)

watch(isActive, (value) => {
  if (value) {
    if (root.value instanceof HTMLElement) {
      setTimeout(() => {
        root.value.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }, 325)
    }
  }
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.item)?.labels))
const description = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.item)?.descriptions, 'i18n_richtext_en'))
</script>

<style module>
.root {
  --core-text--heading--margin-top: var(--lh, 1em);
  --core-text--heading--margin-bottom: 0;
}

.root:not(:last-of-type) {
  border-bottom: var(--stroke--default) solid var(--color--gray);
}

.header {
  composes: reset-heading font-size-small from global;

  transition: color var(--transition--default), background-color var(--transition--default);

  --button-round--color: var(--color--gray);
  --button--stroke: var(--color--gray);
}

.header:hover,
.header[data-state="open"] {
  color: var(--color--blue);
  --button--background-color: var(--color--blue--light);
  --button-round--color: var(--color--blue);
  --button--stroke: var(--color--blue);
}

.trigger {
  composes: reset-button from global;

  width: 100%;
  text-align: left;

  display: grid;
  grid-template-columns: 1fr var(--width--button--round);
  gap: var(--unit--horizontal);
  align-items: center;

  padding: var(--unit--vertical) var(--unit--spacer);
}

.content {
  margin-left: 0;
  overflow: hidden;
}

.content[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.content[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.text {
  composes: font-size-small from global;
  color: var(--color--gray--mid);

  margin: var(--unit--vertical) var(--unit--spacer);
  margin-top: calc(var(--lh) - var(--unit--vertical));

  --core-text--heading--font-size: var(--font-small--font-size);
  --core-text--heading--line-height: var(--font-small--line-height);
  --core-text--heading--letter-spacing: var(--font-small--letter-spacing);
}

.buttonContainer {
  position: relative;
}

.button {
  composes: button-round from global;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --button-round--stroke: var(--stroke--default);
}

.trigger[data-state="open"] .plus {
  display: none;
}

.trigger[data-state="closed"] .minus {
  display: none;
}

.plus,
.minus {
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
</style>
