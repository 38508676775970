<template>
  <section :class="$style.root">
    <nav
      v-if="block?.faq_entries?.data?.length > 1"
      :class="[$style.nav, !withinModal ? 'container--default' : null]"
    >
      <div :class="$style.navContainer" ref="navElement">
        <button
          v-for="faq in block?.faq_entries?.data"
          @click="activeFaqId = faq?.id"
          :class="$style.button"
          :data-current="activeFaqId === faq?.id ? '' : null"
        >
          {{ getLabel(faq) }}
        </button>
      </div>
    </nav>

    <div
      v-for="faq in block?.faq_entries?.data"
      :key="faq?.id"
      v-show="activeFaqId === faq?.id"
      :class="!withinModal ? 'container--default' : null"
    >
      <CoreBlocksFaqsFaqComponent
        :faq="faq"
      />
    </div>
  </section>
</template>

<script setup>
const navElement = ref(null)

const props = defineProps({
  block: Object,
  withinModal: {
    type: Boolean,
    default: false
  }
})

const activeFaqId = ref(props?.block?.faq_entries?.data[0]?.id)

const getLabel = (item) => i18nGetLocalizedString(i18nGetLocalizedData(item?.attributes)?.titles)

useMutationObserver(navElement, (mutations) => {
  for (const mutation of mutations) {
    if (mutation?.type === 'attributes' && mutation?.attributeName === 'data-current' && mutation?.target?.hasAttribute('data-current')) {
      const left = mutation?.target?.getBoundingClientRect()?.left
      const parentLeft = navElement?.value?.getBoundingClientRect()?.left
      const scroll = (left - parentLeft)
      navElement?.value?.scroll({ left: scroll, behavior: 'smooth'})
    }
  }
}, {
  childList: true,
  attributes: true,
  subtree: true
})
</script>

<style module>
.root {
}

.nav {
  margin-bottom: calc(var(--unit--vertical) * 2);
}

.navContainer {
  composes: font-size-small reset-scrollbar from global;

  display: flex;
  justify-content: flex-start;

  overflow: scroll;
  scroll-snap-type: x mandatory;

  margin-left: calc(var(--unit--horizontal) * -1);
  margin-right: calc(var(--unit--horizontal) * -1);

  mask-image: linear-gradient(to right, transparent, black var(--unit--horizontal), black calc(100% - var(--unit--horizontal)), transparent);
}


.button {
  composes: reset-button from global;

  position: relative;
  color: var(--color--black);
  transition: color var(--transition--default);

  scroll-snap-align: start;
  flex-shrink: 0;

  padding-left: var(--unit--horizontal);
}

.button {
  padding-right: var(--unit--horizontal);
}

.button[data-current],
.button:hover {
  color: var(--color--blue);
}
</style>
