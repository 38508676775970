<template>
  <div
    :class="[$style.root, widthClassString]"
  >
    <CoreTextComponent
      v-if="description"
      :class="$style.description"
      :string="description"
    />

    <ul :class="$style.list">
      <li
        v-for="(file, index) in downloads"
        :class="$style.item"
      >
        <a :href="file.url" :class="$style.link" download target="_blank">
          <span :class="$style.label">{{ file.label }}</span> <span :class="$style.details">{{ file?.mime?.split('/')?.pop()?.toUpperCase() }}, {{ file?.humanSize }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  block: {
    type: [Object, Array]
  },
  withinModal: {
    type: Boolean,
    default: false
  }
})

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

const widthClassString = computed(() => {
  if (!props?.withinModal) {
    return `container--default`
  } else {
    return null
  }
})

const downloads = computed(() => {
  return props?.block?.downloads?.map(file => {
    const attributes = baseStrapiGetAttributes(file)
    const label = i18nGetLocalizedString(i18nGetLocalizedData(file?.labels))
    return {
      ...attributes,
      label: label?.trim(),
      humanSize: attributes?.size ? `${(attributes?.size / 1000).toFixed(2)}MB` : null,
      url: baseStrapiGetAbsoluteFileUrl(attributes?.url, attributes?.provider)
    }
  })?.filter(Boolean)
})
</script>

<style module>
.root {
}

.description {
  composes: font-size-x-small from global;
  color: var(--color--gray);
  margin-bottom: var(--unit--spacer);
}

.list {
  composes: reset-list from global;
}

.link {
  composes: reset-link from global;
}

.link:hover .label {
  text-decoration-line: none;
}

.label {
  text-decoration-line: underline;
  text-decoration-color: var(--color--blue);
}
</style>
